<script setup>
import { computed } from 'vue';
import { GlAlert, GlSprintf, GlLink } from '@gitlab/ui';
import { isValidAPIError, errorMessageFromAPI } from '~/helpers/error_utilities';

const props = defineProps({
  // An axios error, or an array of axios errors
  error: {
    type: [Object, Array],
    required: true,
  },
});

defineEmits(['dismiss']);

const errors = computed(() => (Array.isArray(props.error) ? props.error : [props.error]));
const validErrors = computed(() => errors.value.filter((e) => isValidAPIError(e)));

const messages = computed(() => {
  return validErrors.value.map((e) => errorMessageFromAPI(e));
});
</script>

<template>
  <gl-alert variant="danger" class="gl-my-4" @dismiss="$emit('dismiss')">
    <p v-for="(message, k) in messages" :key="k" data-testid="error-message" class="gl-mb-4">
      {{ message }}
    </p>

    <gl-sprintf message="If the problem persists, submit a %{linkStart}support ticket%{linkEnd}.">
      <template #link="{ content }">
        <gl-link
          href="https://support.gitlab.com/hc/en-us/requests/new?ticket_form_id=4414917877650"
          target="_blank"
        >
          {{ content }}
        </gl-link>
      </template>
    </gl-sprintf>
  </gl-alert>
</template>
