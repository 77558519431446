import { useQuery } from '@tanstack/vue-query';
import { getAllTenants } from '~/api/tenants';

export const useTenantsQuery = ({ enabled } = { enabled: true }) => {
  const queryKey = ['tenants'];

  const query = useQuery({
    queryFn: () => getAllTenants().then((res) => res.data.data),
    queryKey,
    staleTime: 30000,
    enabled,
  });

  const { data, error } = query;

  return {
    queryKey,
    data,
    error,
  };
};
