/* eslint-disable @gitlab/no-runtime-template-compiler, import/no-default-export */

import { createApp } from 'vue';
import { GlToast } from '@gitlab/ui';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { startSentry } from '~/helpers/sentry_helper';

export const addPlugins = (app) => {
  app.use(GlToast);
  app.use(VueQueryPlugin, {
    enableDevtoolsV6Plugin: true,
  });
};

const switchboardEnvironment = gon.switchboard_environment;

// eslint-disable-next-line max-params
export default function mountPage(
  pageComponent,
  mapDatasetToProps = null,
  elementContainerId = 'page',
  { router = null } = {},
) {
  let app;

  window.addEventListener('load', () => {
    const element = document.getElementById(elementContainerId);
    const props = mapDatasetToProps ? mapDatasetToProps(element.dataset) : { ...element.dataset };

    app = createApp(pageComponent, { ...props });

    if (router) {
      app.use(router);
    }

    app.config.warnHandler = (msg, instance, trace) => {
      if (!trace.includes('<Gl')) {
        // eslint-disable-next-line no-console
        console.warn('Switchboard compat warning:', msg, trace);
      }
    };

    addPlugins(app);

    if (switchboardEnvironment === 'production') {
      startSentry(app);
    }

    app.mount(`#${element.id}`);

    return app;
  });
}
