import { captureException } from '@sentry/vue';

/**
 * Create a promise rejection based on a message and an axios error
 * @param {String} msg
 * @param {Object} error
 *
 * @returns {Promise}
 */
export const rejectWithMessage = (msg, error) =>
  // eslint-disable-next-line prefer-promise-reject-errors
  Promise.reject({
    ...error,
    response: {
      ...error.response,
      data: {
        message: msg,
      },
    },
  });

export const errorInterceptor = (error) => {
  if (error.code === 'ECONNABORTED') {
    return rejectWithMessage('Request timed out', error);
  }

  const { status, headers } = error.response;
  if ((status >= 300 && status < 400) || status === 422) {
    /**
     * 3xx status codes are not errors that we need to track, as they are already
     * controlled by backend.
     *
     * 422 is a special case for validation errors, and we also don't need
     * to track it with Sentry
     */
    return Promise.reject(error);
  }

  // 404s don't have a response.data object, so we need to add one for our api error alerts
  if (status === 404) {
    return rejectWithMessage('The requested resource was not found', error);
  }

  // 500s don't have a response.data object, so we need to add one for our api error alerts
  if (status === 500) {
    return rejectWithMessage(
      `System error. Please contact your administrator with the following request ID: ${headers['x-request-id']}`,
      error,
    );
  }

  captureException(error);

  // Make sure to re-reject for try/catches to work
  return Promise.reject(error);
};
