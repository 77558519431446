<script setup>
import { ref, computed, onBeforeUnmount } from 'vue';
import { GlModal, GlSearchBoxByType } from '@gitlab/ui';
import { useTenantsQuery } from '~/queries/use_tenants_query';
import { useUserQuery } from '~/queries/use_user_query';

const { data: tenants } = useTenantsQuery();
const { isGitlabUser } = useUserQuery();

const searchTenantModalVisible = ref(false);

const searchTenantFilter = ref('');
const filteredTenants = computed(() => {
  if (!searchTenantFilter.value.length) return tenants.value;
  const search = searchTenantFilter.value.trim().toLowerCase();

  return tenants.value.filter((t) =>
    [t.name, t.internal_reference].some((term) => term?.toLowerCase().includes(search)),
  );
});

const searchTenantListener = (event) => {
  if (!isGitlabUser.value) return;
  if (searchTenantModalVisible.value) {
    if (event.key !== 'Escape') {
      event.stopPropagation();
    }
  }

  // Check if the pressed key is 'K' (key code 75)
  if (event.keyCode === 75) {
    // Check if Command key (Mac) or Control key (Windows) is pressed
    if (event.metaKey || event.ctrlKey) {
      event.preventDefault();

      searchTenantModalVisible.value = true;
    }
  }
};

/**
 * We have to set the `true` on the third param here to make sure this
 * event is added on the `capture` phase, so that we can `stopPropagation`
 * on other keydown listeners such as the `p` and `n` added by main navbar
 * (which are added on the bubbling phase).
 * Otherwise when the user types these letters into the search box they will
 * navigate away from the page.
 *
 * This would be otherwise solved by stopping the event in the input normally
 * however gitlab UI only emits the `string` for the event, and not the event
 * itself which makes it impossible for us to stop it
 */
window.addEventListener('keydown', searchTenantListener, true);
onBeforeUnmount(() => {
  window.removeEventListener('keydown', searchTenantListener);
});
</script>

<template>
  <gl-modal
    v-model="searchTenantModalVisible"
    modal-id="search-tenant-modal"
    title="Tenant quick search"
    data-testid="search-tenant-modal"
    hide-footer
  >
    <gl-search-box-by-type
      v-model="searchTenantFilter"
      data-testid="search-input"
      placeholder="Search"
    />

    <ul class="gl-mt-4">
      <li v-for="filteredTenant in filteredTenants" :key="filteredTenant.id">
        <a data-testid="tenant-link" :href="`/tenants/${filteredTenant.id}/overview`"
          >{{ filteredTenant.name }} ({{ filteredTenant.internal_reference }})</a
        >
      </li>
    </ul>
  </gl-modal>
</template>
