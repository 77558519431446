import { useQuery } from '@tanstack/vue-query';
import { getPCLStatus } from '~/api/pcl';

export const usePCLQuery = () => {
  const queryKey = ['pcl'];

  const query = useQuery({
    queryFn: () => getPCLStatus().then((res) => res.data.data),
    queryKey,
    staleTime: 30 * 60 * 1000, // 30 min
  });

  const { data, error } = query;

  return {
    queryKey,
    data,
    error,
  };
};
