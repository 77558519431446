/**
 * Parses an axios return data for an error message
 * @param {*} data - The data object from an Axios call
 * @param {Array|String} data.errors
 * @param {String} data.message
 * @returns
 */
export const errorMessageFromAPI = (data) => {
  const messageWithDot = data.message.endsWith('.') ? data.message : `${data.message}.`;
  if (!data.errors?.length) return messageWithDot;

  if (Array.isArray(data.errors)) {
    return `${messageWithDot} ${data.errors.join(' ')}`;
  }

  return `${messageWithDot} ${data.errors}`;
};

export const isValidAPIError = (e) => {
  if (!e) return false;
  return 'message' in e || e.errors?.length;
};
