import * as Sentry from '@sentry/vue';

export const startSentry = (app) => {
  Sentry.init({
    app,
    dsn: gon.sentry_frontend_dsn,
    integrations: [Sentry.replayIntegration()],
    release: gon.switchboard_version,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^\//], // only trace internal requests

    trackComponents: true,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};
