// eslint-disable-next-line no-restricted-imports
import axios from 'axios';

import { errorInterceptor } from '~/helpers/axios_interceptor_helpers';

function fetchCSRF() {
  return document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
}

const instance = axios.create({
  baseURL: '/',
  timeout: 7000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

instance.interceptors.request.use((config) => {
  const additionalConfig = config;
  if (additionalConfig.method !== 'get') {
    additionalConfig.headers['X-CSRF-Token'] = fetchCSRF();
  }

  return additionalConfig;
});

instance.interceptors.response.use((success) => success, errorInterceptor);

/* eslint-disable import/no-default-export */
export default instance;
