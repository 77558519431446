import mountVueComponent from '~/helpers/mount_vue_page';
import MainNavbar from '~/layout/navbar/main_navbar.vue';

const mapDatasetToProps = (dataset) => {
  const props = { ...dataset };
  props.tenant = props.tenant && JSON.parse(props.tenant);
  props.links = JSON.parse(props.links);
  props.userLogin = JSON.parse(props.userLogin);

  return props;
};

mountVueComponent(MainNavbar, mapDatasetToProps, 'js-layout-navbar');
